@charset "utf-8";

@import '../../_inc/variables.scss';
@import '../../_inc/mixins.scss';

// ベース
body {
  background-color: $theme-light_gray;
  color: $theme-text_color;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", arial, sans-serif;
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1 {
  color: $theme-main_orange;
  @include fontCalc(32);
  font-weight: bold;
  @include mq() {
    @include fontCalc(48);
  }
}

h2 {
  color: $theme-main_orange;
  @include fontCalc(24);
  font-weight: normal;
  @include mq() {
    @include fontCalc(32);
  }
}

h3 {
  color: $theme-green;
  @include fontCalc(20);
  font-weight: bold;
  @include mq() {
    @include fontCalc(26);
  }
}

h4 {
  @include fontCalc(18);
  font-weight: bold;
  @include mq() {
    @include fontCalc(20);
  }
}

h5 {
  font-weight: bold;
  @include mq() {
    @include fontCalc(18);
  }
}

h5 {
  font-weight: bold;
  @include mq() {
    @include fontCalc(16);
  }
}

button, input, optgroup, select, textarea {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", arial, sans-serif;
}

// ユーティリティクラス
.inner {
  margin: auto;
  max-width: 1200px;
  width: 90%;
}

.inner-l {
  @include mq() {
    margin: auto;
    max-width: 1200px;
    width: 90%;
  }
}

.hero-img {
  max-height: 300px;
  overflow: hidden;
  border-bottom: 4px solid #e8e8e8;
  @include mq() {
    border-width: 8px;
  }
  > img{
    width: 100%;
  }
}

.header-page__title {
  padding-bottom: 100px;
  background: url(../img/bg-page_title.png) no-repeat center top;
  background-size: 100% auto;
  @include mq() {
    padding-bottom: 200px;
  }
  @include mq(tb) {
    padding-bottom: 280px;
  }
  > h2 {
    position: absolute;
    top: -42.5px;
    margin: auto;
    width: 100%;
    @include mq() {
      top: -85px;
    }
  }
}
.header-page__title.form-header {
  padding-bottom: 0;
  z-index: -1;
  @include mq() {
    padding-bottom: 11vw;
  }
  @include mq(pc) {
    padding-bottom: 180px;
  }
  > h2 {
    position: static;
    padding: 1em 0;
    @include mq() {
      padding: 5vw 0 0;
    }
    @include mq(tb) {
      padding: 80px 0 0;
    }
  }
}

.order-1,
.order-2 {
  min-height: 0%;
}

// フォント
.roboto {
  font-family: 'Roboto', sans-serif;
}

// 幅
.w-35 {
  width: 35%;
}

.w-35-l {
  @include mq() {
    width: 35%;
  }
}

.w-49 {
  width: 49%;
}

.w-49-l {
  @include mq() {
    width: 49%;
  }
}

// 影
.shadow-1_b {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
}

// ボタン
.btn {
  padding: 0.875rem 0;
  display: block;
  color: #FFF;
  border-radius: 2em;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .24);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: $btn-trans;
}

.btn-s {
  padding: 0.375em 0;
}

.btn.btn-dib {
  display: inline-block;
  padding-right: 2em;
  padding-left: 2em;
}

.btn.btn-s.btn-dib {
  padding-right: 1em;
  padding-left: 1em;
}

.btn.btn-orange {
  background-color: $theme-main_orange;
  border: 5px solid #eeb518;
  &:hover {
    border-color: transparent;
  }
  &:active {
    border-color: transparent;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .1);
  }
}

.btn.btn-green {
  background-color: $theme-green;
  border: 5px solid #6cefba;
  &:hover {
    border-color: transparent;
  }
  &:active {
    border-color: transparent;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .1);
  }
}

.btn.btn-blue {
  background-color: #2867a8;
  border: 5px solid #579ae0;
  &:hover {
    border-color: transparent;
  }
  &:active {
    border-color: transparent;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .1);
  }
}

.btn.arrow-right{
  padding-right: 3em;
  position: relative;
  &::after{
    display: inline-block;
    content: ('▶');
    position: absolute;
    top: .8em;
    right: 1em;
    font-size: .75em;
  }
  &.btn-s{
    padding-right: 2em;
    &::after{
      right: .75em;
    }
    @include mq(){
      &::after{
        right: 1em;
      }
    }
  }
}

.link {
  padding-bottom: 0.5rem;
  color: $theme-main_orange;
  font-weight: bold;
  border-bottom: 1px dotted $theme-gray;
  &:hover {
    opacity: 0.7;
  }
}

// 背景
.bg-circle__b {
  padding: 2rem 0;
  background: url(../img/bg-circle_b.png) no-repeat center bottom;
  background-size: 100% auto;
  @include mq() {
    padding: 4rem 0;
  }
}

.bg-circle__t {
  padding: 2rem 0;
  background: url(../img/bg-circle_t.png) no-repeat center top;
  background-size: 100% auto;
  @include mq() {
    padding: 4rem 0;
  }
}

.bg-concier__green {
  padding: 2rem 0;
  background: url(../img/bg-concier_g.png) left top fixed;
  @include mq(sp) {
    background-size: 140px auto;
  }
  @include mq() {
    padding: 4rem 0;
  }
}

// ボーダー
.b-color__beige {
  margin: 1em auto;
  width: 6rem;
  height: 3px;
  background: url(../img/border-base_b.png) repeat-x;
  border: none;
  @include mq() {
    margin: 1.5em auto;
  }
}

.b-color__beige-l {
  margin: 1em auto;
  width: 100%;
  height: 5px;
  background: url(../img/border-base_b.png) repeat-x;
  border: none;
  @include mq() {
    margin: 1.5em auto;
  }
}

.b-color__green {
  margin: 1em auto;
  width: 6rem;
  height: 3px;
  background: url(../img/border-base_g.png) repeat-x;
  border: none;
  @include mq() {
    margin: 1.5em auto;
  }
}

.b-color__green-l {
  margin: 1em auto;
  width: 100%;
  height: 5px;
  background: url(../img/border-base_g.png) repeat-x;
  border: none;
  @include mq() {
    margin: 1.5em auto;
  }
}

// 円
.circle-300 {
  position: relative;
  display: block;
  padding: 22.5px;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border-style: solid;
  border-width: 5px;
  @include mq() {
    padding: 45px;
    width: 300px;
    height: 300px;
    border-width: 10px;
    border-radius: 300px;
  }
}

.circle-200 {
  position: relative;
  display: block;
  padding: 15px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border-style: solid;
  border-width: 6px;
  @include mq() {
    padding: 30px;
    width: 200px;
    height: 200px;
    border-width: 12px;
    border-radius: 200px;
  }
}

.circle-170 {
  position: relative;
  display: block;
  padding: 12.75px;
  width: 85px;
  height: 85px;
  border-radius: 85px;
  border-style: solid;
  border-width: 4px;
  @include mq() {
    padding: 25.5px;
    width: 170px;
    height: 170px;
    border-width: 8px;
    border-radius: 170px;
  }
}

.circle-100 {
  position: relative;
  display: block;
  padding: 7.5px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border-style: solid;
  border-width: 4px;
  @include mq() {
    padding: 14px;
    width: 100px;
    height: 100px;
    border-width: 8px;
    border-radius: 100px;
  }
}

.circle-300,
.circle-200,
.circle-170,
.circle-100 {
  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 70%;
    z-index: 5;
  }
}

.circle-bg__white {
  background: #FFF;
}

.circle-bg__yellow {
  background: $theme-main_yellow;
}

.border-color__gray {
  border-color: #f5f5f5;
}

.border-color__darkgray {
  border-color: #e8e8e8;
}

.border-color__orange {
  border-color: $theme-main_orange;
}

// フォーム
.reset-form__def {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
}

//見出し
.bl_green{
  position: relative;
  margin: 0 0 .5em;
  padding: 0 0 0 calc(6px + 1em);
  &::before{
    display: block;
    content: "";
    width: 6px;
    height: 100%;
    background-color: $theme-green;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
  }
  + hr{
    margin: 0 auto 1.5em;
    height: 3px;
    @include mq() {
      margin-bottom: 2em;
    }
  }
}

// ヘッダー
.global-header {
  position: relative;
  padding-top: 13px;
  font-weight: bold;
  @include mq() {
    padding-bottom: 13px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .24);
  }
  hr {
    margin: 13px 0;
    height: 1px;
    background: $theme-gray;
    border: none;
  }
}

.header-logo {
  margin: 0;
  width: 242px;
  height: 33px;
  line-height: 1;
  @include mq(sp) {
    margin: 0 0 10px 8px;
  }
  > a {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/logo-header_g.png) no-repeat;
    background-size: 242px auto;
    text-indent: -9999px;
  }
}

.global-nav {
  &.-sp {
    display: block;
    @include mq() {
      display: none;
    }
  }
  &.-pc {
    display: none;
    @include mq() {
      display: block;
    }
  }
}

.list-global__nav {
  margin: 0;
  padding: 0;
  @include mq() {
    text-align: center;
  }
  > li {
    @include mq() {
      display: inline-block;
      margin: 0 0.5rem;
      text-align: center;
    }
    > a {
      @include mq() {
        display: inline-block;
        padding: 0.375em 1em;
        color: $theme-text_color;
        border: 5px solid transparent;
        border-radius: 2em;
        text-decoration: none;
        transition: $btn-trans;
        &:hover {
          background-color: $theme-main_yellow;
          color: #FFF;
        }
        &:active {
          background-color: $theme-main_orange;
          border: 5px solid #eeb518;
          color: #FFF;
        }
      }
    }
  }
}

.list-header__contact {
  margin: 0;
  padding: 0;
  @include mq() {
    position: absolute;
    right: 0;
    top: 0;
  }
  > li {
    @include mq() {
      float: left;
      &:first-child {
        margin-right: 20px;
      }
    }
    &.list-tel {
      > span {
        position: relative;
        padding-left: 34px;
        font-weight: bold;
        line-height: 30px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          display: block;
          margin-right: 8px;
          width: 24px;
          height: 24px;
          background: url(../img/header-icon_tel.png) no-repeat center $theme-green;
          background-size: 12px;
          border: 3px solid #6cefba;
          border-radius: 30px;
        }
      }
      > a {
        color: $theme-text_color;
        text-decoration: none;
      }
      @include mq() {
        @include fontCalc(18);
      }
    }
    &.list-contact {
      > a {
        display: inline-block;
        padding: 0.175em 1em;
        color: #FFF;
        border-radius: 2em;
        text-decoration: none;
        background-color: $theme-green;
        border: 3px solid #6cefba;
        transition: $btn-trans;
        &:hover {
          border-color: transparent;
        }
        &:active {
          border-color: transparent;
          box-shadow: inset 0 0 4px rgba(0, 0, 0, .1);
        }
        @include fontCalc(14);
        > span {
          display: inline-block;
          padding-left: 18px;
          background: url(../img/header-icon_mail.png) no-repeat left center;
          background-size: 14px;
        }
      }
    }
  }
}

.btn-menu {
  position: absolute;
  top: 0;
  right: 8px;
  overflow: hidden;
  display: block;
  width: 33px;
  height: 33px;
  &.-open {
    > a {
      background-position: left bottom;
    }
  }
  > a {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/btn-menu.png) no-repeat left top;
    background-size: 33px auto;
    text-indent: -9999px;
  }
}

.nav-wrap {
  position: absolute;
  top: 43px;
  display: none;
  width: 100%;
  background: #FFF;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, .16), 0 2px 4px rgba(0, 0, 0, .16);
  z-index: 15000;
  .list-global__nav {
    > li {
      border-bottom: 1px dashed $theme-gray;
      > a {
        display: block;
        padding: 0.75rem;
        background: url(../img/icon-arrow_g.png) no-repeat 98% center;
        background-size: 7px auto;
        color: $theme-text_color;
        text-decoration: none;
      }
    }
  }
  .list-header__contact {
    text-align: center;
    > li {
      padding: 0.75rem 0;
      &.list-tel {
        border-bottom: 1px dashed $theme-gray;
        color: $theme-text_color;
        > a {
          color: $theme-text_color;
        }
      }
    }
  }
}

// フッター
.global-footer {
  padding-top: 68px;
  @include mq() {
    padding-top: 136px;
  }
}

.global-footer__wrap {
  padding-bottom: 30px;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, .16), 0 3px 5px rgba(0, 0, 0, .24);
  @include mq() {
    padding-bottom: 60px;
  }
  > .inner {
    padding-top: 35px;
    @include mq() {
      padding-top: 80px;
    }
  }
}

.btn-pagetop {
  position: absolute;
  left: 50%;
  top: -25px;
  margin: 0 0 0 -25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  @include mq() {
    top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  > a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: $theme-main_orange;
    border: 4px solid $theme-main_yellow;
    border-radius: 50px;
    text-align: center;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, .24);
    transition: $btn-trans;
    @include mq() {
      border-width: 8px;
      border-radius: 100px;
    }
    &:hover {
      background-color: $theme-main_yellow;
      box-shadow: 1px 5px 6px rgba(0, 0, 0, .24);
    }
    > img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      @include mq(sp) {
        width: 28px;
      }
    }
  }
}

.footer-company__info {
  @include mq(sp) {
    margin-bottom: 30px;
  }
  > figure {
    position: relative;
    margin: auto;
    width: 200px;
    height: 200px;
    border: 8px solid #F6F6F6;
    border-radius: 200px;
    > img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 133px;
    }
  }
  > dl {
    margin: 0;
    > dt {
      margin: 1em 0;
      @include fontCalc(18);
      font-weight: bold;
    }
    > dd {
      margin: 0;
    }
  }
}

.footer-sitemap___heading {
  margin: 0;
  color: $theme-green;
  & + .b-color__beige {
    width: 4rem;
  }
}

.list-site__directories {
  margin: 0;
  padding: 0;
  > li {
    border-bottom: 1px dotted $theme-gray;
    @include mq() {
      float: left;
      width: 48%;
      &:nth-child(even) {
        float: right
      }
    }
    > a {
      display: block;
      padding: 1em 0 1em 1em;
      background: url(../img/footer-icon_arrow.png) no-repeat left center;
      color: $theme-text_color;
      text-decoration: none;
      @include mq(sp) {
        background-size: 4px auto;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}

.copyright {
  color: $theme-green;
  @include fontCalc(14);
  font-weight: bold;
}
a.text-link{
  display: inline;
  padding-bottom: .25em;
  color: $theme-main_orange;
  text-decoration: none;
  @include fontCalc(14);
  border-bottom: 1px dotted $theme-gray;
  @include mq(){
    @include fontCalc(16);
  }
}